import styled from 'styled-components';
import { fontEllipsis, fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const StyledContent = styled.div`
  padding: 8px;
  height: 64px;
  background: white;

  @media ${SM_AND_UP_QUERY} {
    padding: 24px;
    height: 129px;
  }
`;

export const StyledDateMobile = styled.div`
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 6px;
  ${fontStyle('10px')};

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 16px;
    ${fontStyle('14px')};
    display: none;
  }
`;

export const StyledDateDesktop = styled(StyledDateMobile)`
  display: none;

  @media ${SM_AND_UP_QUERY} {
    display: block;
  }
`;

export const StyledTitle = styled.div`
  color: #16161d;
  ${fontEllipsis(2)};
  ${fontStyle('12px', '700')};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('20px', '700')};
  }
`;

export const StyledImageContainer = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height + 1}px;
  position: relative;
  object-fit: contain;
  background: #16161d;
`;

export const StyledUtilityCollectionEndIcon = styled.img`
  width: 113px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${SM_AND_UP_QUERY} {
    width: 150px;
  }
`;
