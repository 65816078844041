import styled from 'styled-components';
import { Row, fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const NetworkWrapper = styled(Row)`
  margin-right: 48px;
  cursor: pointer;
  position: relative;
`;

export const StyledNetworkText = styled.div`
  ${fontStyle('16px', '500')};
  color: ${({ theme }) => theme.colors.brightDream};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('14px', '500')};
  }
`;
