import Binance from '../assets/Binance.svg';
import Discord from '../assets/Discord.svg';
import Ethereum from '../assets/Ethereum.svg';
import Facebook from '../assets/Facebook.svg';
import Instagram from '../assets/Instagram.svg';
import LINE from '../assets/LINE.svg';
import Linkedin from '../assets/Linkedin.svg';
import Medium from '../assets/Medium.svg';
import Polygon from '../assets/Polygon.svg';
import ThunderCore from '../assets/ThunderCore.svg';
import Twitter from '../assets/Twitter.svg';
import WebsiteBg from '../assets/background.svg';
import backgroundMobile from '../assets/background_mobile.jpg';
import checkMark from '../assets/check_mark.svg';
import close from '../assets/close.svg';
import downArrow from '../assets/down_arrow.svg';
import ghostFrightened from '../assets/ghost_frightened.svg';
import gridiconsNotice from '../assets/gridicons_notice.svg';
import headerLogo from '../assets/header_logo.svg';
import homeLogo from '../assets/home_logo.svg';
import imageError from '../assets/img_error.svg';
import ghostParty from '../assets/img_ghost_party.svg';
import walletJoyId from '../assets/img_joyid.svg';
import IconImage from '../assets/logo.svg';
import redreamer from '../assets/logo_icon.svg';
import powerByRedreamerBlack from '../assets/power_by_redreamer_black.svg';
import powerByRedreamerWhite from '../assets/power_by_redreamer_white.svg';
import refreshRounded from '../assets/refresh-rounded.svg';
import refresh from '../assets/refresh.svg';
import unbinding from '../assets/unbinding.svg';
import utilityCollectionDefault from '../assets/utilityCollection_default.svg';
import utilityCollectionEnd from '../assets/utilityCollection_end.svg';
import walletMetamask from '../assets/wallet-metamask.svg';
import walletConnect from '../assets/wallet_connect.svg';
import walletOKX from '../assets/wallet_okx.svg';
import walletTT from '../assets/wallet_tt.svg';
import whiteTriangle from '../assets/white_triangle.svg';
import zondiconsNetwork from '../assets/zondicons_network.svg';
import zondiconsNetworkPurple from '../assets/zondicons_network_purple.svg';

export const EthereumIcon = Ethereum;
export const ThunderCoreIcon = ThunderCore;
export const BinanceIcon = Binance;
export const PolygonIcon = Polygon;
export const unbindingIcon = unbinding;
export const zondiconsNetworkIcon = zondiconsNetwork;
export const zondiconsNetworkPurpleIcon = zondiconsNetworkPurple;
export const checkMarkIcon = checkMark;
export const closeIcon = close;
export const utilityCollectionEndIcon = utilityCollectionEnd;
export const refreshIcon = refresh;
export const downArrowIcon = downArrow;
export const gridiconsNoticeIcon = gridiconsNotice;
export const refreshRoundedIcon = refreshRounded;
export const whiteTriangleIcon = whiteTriangle;
export const redreamerIcon = redreamer;
export const ghostFrightenedIcon = ghostFrightened;
export const utilityCollectionDefaultImg = utilityCollectionDefault;
export const walletMetamaskImg = walletMetamask;
export const walletConnectImg = walletConnect;
export const walletTTImg = walletTT;
export const walletJoyIdImg = walletJoyId;
export const ghostPartyIcon = ghostParty;
export const walletOKXImg = walletOKX;
export const headerLogoImg = headerLogo;
export const backgroundMobileImg = backgroundMobile;

export const LinkedinIcon = Linkedin;
export const FacebookIcon = Facebook;
export const DiscordIcon = Discord;
export const TwitterIcon = Twitter;
export const MediumIcon = Medium;
export const InstagramIcon = Instagram;
export const LINEIcon = LINE;

export const homeLogoImage = homeLogo;
export const LogoImage = IconImage;
export const imageErrorIcon = imageError;
export const WebsiteBgImage = WebsiteBg;
export const powerByRedreamerBlackIcon = powerByRedreamerBlack;
export const powerByRedreamerWhiteIcon = powerByRedreamerWhite;
