import React from 'react';
import styled from 'styled-components';
import { StyledMaskIcon } from '@styles/common';
import { closeIcon } from '@styles/imgMapping';
import { SM_AND_UP_QUERY } from '@/breakpoints';

const StyledContainer = styled.div`
  height: 24px;
  width: 24px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media ${SM_AND_UP_QUERY} {
    display: none;
  }
`;

const StyledContent = styled.div`
  width: 18px;
  height: 2px;
  margin-bottom: 3px;
  background: ${({ theme }) => theme.colors.brightDream};
`;

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

const Hamburger = ({ isOpen, onClick }: Props) => (
  <StyledContainer onClick={onClick}>
    {isOpen ? (
      <StyledMaskIcon src={closeIcon} width="24px" />
    ) : (
      <>
        <StyledContent />
        <StyledContent />
        <StyledContent />
      </>
    )}
  </StyledContainer>
);

export default Hamburger;
