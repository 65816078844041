import styled from 'styled-components';
import { Row } from '@styles/common';
import { MD_AND_DOWN_QUERY, XS_QUERY } from '@/breakpoints';
import { Mode } from './type';

export const StyledPosition = styled(Row)`
  width: 100%;
  position: relative;
  height: 0;
  margin-top: 50px;

  @media ${MD_AND_DOWN_QUERY} {
    height: 146px;
  }
`;

export const StyledContainer = styled.div<{ background?: string; mode?: Mode }>`
  width: 100%;
  position: absolute;
  top: 0;
  background-color: ${({ theme, mode }) => {
    if (mode === Mode.DARK) return theme.colors.black;
    return theme.colors.white;
  }};
  padding-bottom: 40px;

  @media ${MD_AND_DOWN_QUERY} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px 0 40px;
  }
`;

export const StyledContent = styled(Row)`
  flex-direction: row;

  @media ${MD_AND_DOWN_QUERY} {
    flex-direction: column;
  }
`;

export const StyledSocialMeidaRow = styled.div`
  display: flex;
  align-items: center;

  & > a:last-child > div {
    margin-right: 0;
  }
`;

export const StyledImage = styled.img<{ width?: number; mobileWidth?: number }>`
  width: ${({ width }) => width}px;
  margin-right: 32px;

  @media ${XS_QUERY} {
    width: ${({ mobileWidth }) => mobileWidth}px;
    margin-bottom: 28px;
    margin-right: 0;
  }
`;

export const StyledIcon = styled.div<{
  src?: string;
  mode?: Mode;
  socialIconColor?: string;
}>`
  width: 32px;
  height: 32px;
  mask-image: ${({ src }) => `url(${src})`};
  margin-right: 12px;
  background: ${({ theme, mode, socialIconColor }) => {
    if (socialIconColor) return socialIconColor;
    if (mode === Mode.DARK) return theme.colors.white;
    return theme.colors.black;
  }};

  &:hover {
    background: ${({ theme }) => theme.colors.brightDream};
  }
`;

export const StyledFooterTextContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const StyledFooterText = styled.div<{ mode?: Mode }>`
  font-size: 12px;
  margin-right: 24px;
  color: ${({ mode, theme }) => (mode === Mode.DARK ? theme.colors.white : 'rgba(0, 0, 0, 0.6)')};

  @media ${MD_AND_DOWN_QUERY} {
    margin-right: 0;
    margin-bottom: 16px;
    font-size: 10px;
  }
`;

export const StyledDivide = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 32px 0;
`;

export const StyledBottomRow = styled.div`
  padding: 0 124px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    margin-right: 0;
  }

  @media ${MD_AND_DOWN_QUERY} {
    flex-direction: column;
    padding: 0;

    img {
      margin-bottom: 0;
    }
  }
`;

export const StyledTopContainer = styled(Row)`
  padding: 32px 124px 0px;

  @media ${MD_AND_DOWN_QUERY} {
    padding-top: 0;
    flex-direction: column;
  }
`;
