import styled from 'styled-components';
import { Row, fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledRedemptionWrapper = styled.div`
  padding: 24px 20px;
  background: ${({ theme }) => theme.colors.white};

  @media ${SM_AND_UP_QUERY} {
    padding: 48px 40px;
    border: 5px solid ${({ theme }) => theme.colors.brightDream};
  }
`;

export const StyledRedemptionTitle = styled.div`
  ${fontStyle('20px', '700')};
  margin-bottom: 20px;
  text-align: center;
`;

export const StyledUtilityCollectionImageContainer = styled.div`
  position: relative;
  margin-right: 16px;
  background: ${({ theme }) => theme.colors.black};
  ${size('80px', '80px')};

  @media ${SM_AND_UP_QUERY} {
    margin-right: 48px;
    ${size('300px', '300px')};
  }
`;

export const StyledUtilityCollectionImage = styled.img`
  ${size('100%', '100%')};
  object-fit: contain;
`;

export const StyledUtilityCollectionEndIcon = styled.img`
  width: 53px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media ${SM_AND_UP_QUERY} {
    width: 150px;
  }
`;

export const StyledUtilityCollectionTitle = styled.div`
  ${fontStyle('15px', '700')};
  word-break: break-all;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('20px', '700')};
  }
`;

export const StyledUtilityCollectionDate = styled.div`
  ${fontStyle('11px')};
  margin-bottom: 8px;

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('14px')};
    margin-bottom: 16px;
  }
`;

export const StyledRedemptionContent = styled(Row)`
  align-items: flex-start;
  margin-bottom: 8px;

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 16px;
  }
`;

export const StyledSeeMoreButton = styled.div`
  ${fontStyle('16px', '500')}
  color: ${({ theme }) => theme.colors.brightDream};
`;

export const StyledFlexFillWidth = styled.div`
  flex: 1;
`;

export const StyledDescription = styled.div`
  ${fontStyle('14px')};
  padding: 12px 0 20px 0;
  word-break: break-all;

  p {
    margin: 0;
  }

  @media ${SM_AND_UP_QUERY} {
    padding: 16px 0;
    margin-bottom: 16px;
  }
`;
