import React, { useEffect, useRef, useState } from 'react';
import { getImageUrl } from '@/utils';
import {
  StyledContent,
  StyledImage,
  StyledInValidBackgroud,
  StyledNftTitle,
  StyledRedeemButton,
  StyledTokenId,
  StyledVideo,
  StyledWrapper,
} from './Styles';
import { IUtilityCollectionNFTs } from './type';

type Props = {
  nft?: IUtilityCollectionNFTs;
  defaultImg: string;
  children?: React.ReactNode;
  handleRedeem: (nft: IUtilityCollectionNFTs) => void;
};

const isRedeemable = false;

const CollectionCard = ({ nft, handleRedeem, defaultImg, children }: Props) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [height, setHeight] = useState<number | undefined>();

  const { image = '', name = '', tokenId } = nft || {};

  const handleRedeemRequest = () => {
    if (!nft) return;
    handleRedeem(nft);
  };

  const renderAsset = () => (
    <StyledImage
      height={height}
      ref={imageRef}
      alt="nft"
      objectFit={!image ? 'contain' : ''}
      src={image || defaultImg}
    />
  );

  useEffect(() => {
    if (imageRef.current) {
      const { width } = imageRef.current.getBoundingClientRect();
      setHeight(width);
    }
  }, [imageRef.current]);

  return (
    <StyledWrapper>
      {renderAsset()}
      {children ? (
        <StyledContent>{children}</StyledContent>
      ) : (
        <StyledContent>
          <StyledNftTitle>{name}</StyledNftTitle>
          <StyledTokenId>{`#${tokenId}`}</StyledTokenId>
          <StyledRedeemButton $isRedeemable={isRedeemable} onClick={handleRedeemRequest}>
            {isRedeemable ? 'Validated' : 'Redeem'}
          </StyledRedeemButton>
        </StyledContent>
      )}
      {/* {isRedeemable && <StyledInValidBackgroud />} */}
    </StyledWrapper>
  );
};

export default CollectionCard;
