import styled from 'styled-components';
import { Row, fontStyle, size } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledDescription = styled.div`
  ${fontStyle('14px')};
  color: rgba(0, 0, 0, 0.6);
  padding: 12px 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.32);
  word-break: auto-phrase;

  .pre-wrap {
    white-space: pre-wrap;
  }

  p {
    margin: 0;
  }

  @media ${SM_AND_UP_QUERY} {
    padding: 16px 0;
  }
`;

export const StyledWrapper = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.32);

  @media ${SM_AND_UP_QUERY} {
    padding-top: 16px;
  }
`;

export const StyledMerchantTtile = styled.div<{ marginTop?: string }>`
  ${fontStyle('14px')};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 11px;
  margin-top: ${({ marginTop }) => marginTop};

  @media ${SM_AND_UP_QUERY} {
    margin: 0;
    margin-right: 74px;
    min-width: 121px;
  }
`;

export const StyledRedeemableNft = styled.img`
  ${size('50px', '50px')};
  border-radius: 4px;
  object-fit: cover;
  margin-right: 10px;
`;

export const StyledRedeemableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  grid-gap: 10px;
  width: 100%;
`;

export const StyledDescriptionEleRow = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.black};

  @media ${SM_AND_UP_QUERY} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
`;
