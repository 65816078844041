import styled from 'styled-components';
import { Row, fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledHeader = styled.header<{ isTestnet: boolean }>`
  padding-top: ${({ isTestnet }) => (isTestnet ? '100px' : '60px')};
`;

export const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.brightDream};
  z-index: 5;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: 24px;
  padding: 0 20px 0 18px;

  @media ${SM_AND_UP_QUERY} {
    padding: 0 20px;
    max-width: 1228px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledImage = styled.img`
  height: 32px;
  width: 200px;
  cursor: pointer;

  @media ${SM_AND_UP_QUERY} {
    width: 297px;
  }
`;

export const StyledNoticeBanner = styled(Row)`
  padding: 10px 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.black};
`;

export const StyledNoticeText = styled.div`
  ${fontStyle('12px', '700')};
  color: ${({ theme }) => theme.colors.hope};
`;
