import React from 'react';
import styled from 'styled-components';
import { homeLogoImage } from '@styles/imgMapping';
import { SM_AND_UP_QUERY } from '@/breakpoints';

const StyledContainer = styled.div`
  margin: auto;
  width: 250px;
  padding-top: 32px;

  & > img {
    width: 100%;
  }

  @media ${SM_AND_UP_QUERY} {
    padding-top: 100px;
    width: 403px;
  }
`;

const Logo = () => (
  <StyledContainer>
    <img alt="logo" src={homeLogoImage} />
  </StyledContainer>
);

export default Logo;
