import React, { useState } from 'react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { Row, StyledDesktopRender, StyledMaskIcon, StyledMobileRender } from '@styles/common';
import { downArrowIcon, utilityCollectionDefaultImg, utilityCollectionEndIcon } from '@styles/imgMapping';
import { IUtilityCollectionById } from '@/api/getUtilityCollection';
import {
  StyledDescription,
  StyledFlexFillWidth,
  StyledRedemptionContent,
  StyledRedemptionTitle,
  StyledRedemptionWrapper,
  StyledSeeMoreButton,
  StyledUtilityCollectionDate,
  StyledUtilityCollectionEndIcon,
  StyledUtilityCollectionImage,
  StyledUtilityCollectionImageContainer,
  StyledUtilityCollectionTitle,
} from './Styles';
import { UtilityCollectionDescription } from './UtilityCollectionDescription';

type Props = {
  utilityCollection?: IUtilityCollectionById;
};

const RedemptionInformation = ({ utilityCollection }: Props) => {
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const formatStartTime = utilityCollection ? moment(utilityCollection?.startTime).format('YYYY/M/D HH:mm') : '';
  const formatEndTime = utilityCollection ? moment(utilityCollection?.endTime).format('YYYY/M/D HH:mm') : '';
  const exceedLimit =
    utilityCollection &&
    utilityCollection.maximumRedemptions > 0 &&
    utilityCollection.redeemedCount >= utilityCollection.maximumRedemptions;
  const isUtilityCollectionEnd = utilityCollection ? moment().isAfter(utilityCollection.endTime) || exceedLimit : false;

  return (
    <StyledRedemptionWrapper>
      <StyledMobileRender>
        <StyledRedemptionTitle>Redemption Information</StyledRedemptionTitle>
      </StyledMobileRender>
      <StyledRedemptionContent>
        <StyledUtilityCollectionImageContainer>
          <StyledUtilityCollectionImage
            src={
              utilityCollection?.mediaUrls.image || utilityCollection?.mediaUrls.video || utilityCollectionDefaultImg
            }
          />
          {isUtilityCollectionEnd && <StyledUtilityCollectionEndIcon src={utilityCollectionEndIcon} />}
        </StyledUtilityCollectionImageContainer>
        <StyledFlexFillWidth>
          <StyledUtilityCollectionDate>{`${formatStartTime} ~ ${formatEndTime}`}</StyledUtilityCollectionDate>
          <StyledUtilityCollectionTitle>{utilityCollection?.name}</StyledUtilityCollectionTitle>
          <StyledDesktopRender>
            <UtilityCollectionDescription utilityCollection={utilityCollection} />
          </StyledDesktopRender>
        </StyledFlexFillWidth>
      </StyledRedemptionContent>
      <StyledMobileRender>
        <StyledDescription>
          <ReactMarkdown linkTarget="_blank">{utilityCollection?.description || ''}</ReactMarkdown>
        </StyledDescription>
        {showDescription ? (
          <UtilityCollectionDescription utilityCollection={utilityCollection} />
        ) : (
          <Row useCursor justify="center" onClick={() => setShowDescription(true)}>
            <StyledSeeMoreButton>See More</StyledSeeMoreButton>
            <StyledMaskIcon src={downArrowIcon} width="24px" />
          </Row>
        )}
      </StyledMobileRender>
    </StyledRedemptionWrapper>
  );
};

export default RedemptionInformation;
