import { DefaultTheme } from 'styled-components';

/*
  ---
  XS (phones) – 600px and below
  SM (small to medium tablets) – 601px to 960px
  MD (large tablets to laptop) – 961px to 1264px
  LG (desktop) – 1265px to 1904px
  XL (4k and ultra-wides) – 1905px and above
  ---
  Reference: https://material-ui.com/customization/breakpoints/
*/
const vw = { xs: 600, sm: 960, md: 1264, lg: 1904 };

/*
  breakpoints is detecting the min-width of the viewport
  ex: 601px is the breakpoint for SM
*/
const breakpoints = [
  '1px', // xs
  `${vw.xs + 1}px`, // sm
  `${vw.sm + 1}px`, // md
  `${vw.md + 1}px`, // lg
  `${vw.lg + 1}px`, // xl
];

type BreakpointsAlias = {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  mobile?: string;
};
const breakpointsAlias: BreakpointsAlias = {};

// eslint-disable-next-line max-len
[breakpointsAlias.xs, breakpointsAlias.sm, breakpointsAlias.md, breakpointsAlias.lg, breakpointsAlias.xl] = breakpoints; // aliases

const colors = {
  brightDream: '#9043FF',
  darkDream: '#9043FF',
  brainGrey: '#16161d',
  rose: '#ff2c82',
  hope: '#ffffff',
  gray: '#d9d9d9',
  white: '#ffffff',
  black: '#000000',
  loginWalletWrap: '#9747FF',
  redeemBtnText: '#ffffff',
  hoverRefreshBtn: 'linear-gradient(0deg, rgba(177, 67, 255, 0.05), rgba(177, 67, 255, 0.05)), #FFFFFF;',
};

const metrics = {
  widthXS: vw.xs,
  widthSM: vw.sm,
  widthMD: vw.md,
  widthLG: vw.lg,
  containerPadding: 0,
  topbarHeight: 50,
  sidebarWidth: 270,
  menuItemHeight: 45,
};

const theme: DefaultTheme = {
  breakpoints,
  colors,
  metrics,
};

export default theme;
