import styled from 'styled-components';
import { fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledContainer = styled.div`
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  @media ${SM_AND_UP_QUERY} {
    margin-top: 42px;
    max-width: 100%;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 20px;
  border-top: solid 5px ${({ theme }) => theme.colors.brightDream};
  border-bottom: solid 5px ${({ theme }) => theme.colors.brightDream};

  @media ${SM_AND_UP_QUERY} {
    border: solid 5px ${({ theme }) => theme.colors.brightDream};
    border-top: 0;
    padding: 48px 40px;
    display: block;
  }
`;

export const StyledSelectNftTitle = styled.div`
  ${fontStyle('20px', '700')};
  margin-bottom: 8px;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    margin-bottom: 16px;
    text-align: left;
  }
`;

export const StyledSelectNftSubTitle = styled.div`
  ${fontStyle('16px')};
  margin-bottom: 20px;
  text-align: center;

  @media ${SM_AND_UP_QUERY} {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const StyledSelectNftRow = styled.div`
  @media ${SM_AND_UP_QUERY} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    width: 100%;
  }
`;

export const StyledRefreshContainer = styled.div`
  margin-top: 16px;
`;

export const StyledNtfsRow = styled.div`
  img {
    background: ${({ theme }) => theme.colors.black};
  }

  @media ${SM_AND_UP_QUERY} {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 30px 24px;
  }
`;
