import { JoyIDProvider } from '@joyid/ethers';
import { initConfig } from '@joyid/evm';
import { chains } from './evmWalletConfigs';

const getJoyIdConfig = (chainId: number) => {
  const config = chains.find((c) => c.id === chainId);

  if (!config) {
    return {
      name: 'Domin Network',
      logo: 'https://passport.domin.foundation/favicon.jpg',
      network: {
        chainId: 1,
        name: 'ETH',
      },
      rpcURL: '',
      joyidAppURL: '',
    };
  }

  const joyidAppURL = 'https://app.joy.id';

  const rpcURL = config?.rpc;

  const network = {
    chainId: config!.id,
    name: config!.name,
  };

  const joyIdConfig = {
    name: 'Domin Network',
    logo: 'https://passport.domin.foundation/favicon.jpg',
    network,
    rpcURL,
    joyidAppURL,
  };

  return joyIdConfig;
};

export const initJoyId = (chainId: number) => {
  const config = getJoyIdConfig(chainId);
  initConfig(config);
};

export const getJoyIDProvider = (chainId: number) => {
  const config = getJoyIdConfig(chainId);
  const provider = new JoyIDProvider(config.rpcURL, config.network, config);
  return provider;
};
