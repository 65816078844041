import { Button } from '@mui/material';
import styled from 'styled-components';
import { WebsiteBgImage, backgroundMobileImg } from '@styles/imgMapping';
import { SM_AND_UP_QUERY, XS_QUERY } from '@/breakpoints';
import { SelectWallet } from '@/configs/evmConnecters';
import { Row, fontStyle, size } from '@/styles/common';

export const StyledContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  background: url(${WebsiteBgImage});
  background-color: ${({ theme }) => theme.colors.brightDream};
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @media ${XS_QUERY} {
    background: url(${backgroundMobileImg});
    background-color: ${({ theme }) => theme.colors.brightDream};
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
`;

export const StyledRibbonContainer = styled.div`
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  .content {
    left: -10px;
    top: 30px;
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 225px;
    background-color: white;
    box-shadow: 0 1px 10px rgb(0 0 0 / 20%);
    color: ${({ theme }) => theme.colors.brightDream};
    font: 700 20px / 32px 'Lato', sans-serif;
    text-transform: uppercase;
    text-align: center;
  }

  @media ${XS_QUERY} {
    width: 100px;
    height: 100px;

    .content {
      left: -45px;
      top: 25px;
      height: 30px;
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const StyledAutoSpace = styled.div`
  flex: 1;
  width: 100%;
`;

export const StyledSignContainer = styled(Row)`
  width: 400px;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto 0;

  @media ${XS_QUERY} {
    padding: 0 48px;
    width: auto;
  }
`;

export const StyledWrapper = styled.div`
  padding: 48px;
  margin-top: 32px;
  background: ${({ theme }) => theme.colors.white};
  border-top: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  border-bottom: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};

  @media ${SM_AND_UP_QUERY} {
    margin: 30px auto 0;
    width: 400px;
    border: 5px solid ${({ theme }) => theme.colors.loginWalletWrap};
  }
`;

export const StyledImg = styled.div<{ src: string; selectWallet?: SelectWallet }>`
  background: ${({ src }) => `url(${src})`};
  background-size: ${({ selectWallet }) => (selectWallet === SelectWallet.OKX ? '75%' : 'cover')};
  background-position: ${({ selectWallet }) => (selectWallet === SelectWallet.OKX ? 'center' : 'top')};
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0 auto 12px auto;
  ${size('120px', '120px')};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: white;
`;

export const StyledConnectTitle = styled.div`
  margin-bottom: 8px;
  margin-top: 30px;
  text-align: center;
  ${fontStyle('24px', '700')};
`;

export const StyledConnectDescription = styled.div`
  margin-bottom: 30px;
  text-align: center;
  ${fontStyle('14px')};
  word-break: break-word;
`;

export const StyledDisconnectButton = styled(Button)`
  &&& {
    ${size('100%', '40px')};
    ${fontStyle('0.875rem', '700')};
    padding: 12px 0;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.brightDream};
    text-transform: none;
    border: solid 1px ${({ theme }) => theme.colors.brightDream};

    :hover {
      background-color: ${({ theme }) => `${theme.colors.brightDream}0c`};
    }
  }
`;
