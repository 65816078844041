import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { StyledIconImg, StyledMobileRender } from '@styles/common';
import { checkMarkIcon, zondiconsNetworkIcon } from '@styles/imgMapping';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import useApplicationContext from '@/providers/applicationContext';
import { UnbindAddressButton } from '../UnbindAddressButton';
import useNavbarContext from '../navbarContext';
import {
  StyledCheckMark,
  StyledList,
  StyledNetwork,
  StyledNetworkHover,
  StyledNetworkName,
  StyledTitle,
  StyledTitleWrapper,
  StyledWrapper,
} from './Styles';

type Props = {
  isShow: boolean;
  isTestnet: boolean;
};

const NetworkSwitchList = ({ isShow, isTestnet }: Props) => {
  const { hideNetwork, onSwitchChainRedirect, switchNetworks, mode } = useNavbarContext();
  const { handleMetamaskSwitchNetwork, handleOKXSwitchNetwork, handleJoyIdSwitchNetwork } = useApplicationContext();

  const { chainId: metamaskChainId } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();

  const chainId = metamaskChainId || okxChainId || joyIdchainId;

  const handleSwitchNetwork = async (chain: {
    id: number;
    url: string;
    chainName: string;
    nativeCurrency?: {
      name: string;
      symbol: string;
      decimals: number | string;
    };
  }) => {
    if (joyIdAddress) {
      handleJoyIdSwitchNetwork(chain);
    } else if (okxAddress) {
      await handleOKXSwitchNetwork(chain);
    } else {
      await handleMetamaskSwitchNetwork(chain);
    }
    onSwitchChainRedirect();
  };

  return (
    <StyledWrapper isTestnet={isTestnet} isShow={isShow} mode={mode}>
      {!hideNetwork && (
        <StyledTitleWrapper>
          <StyledIconImg src={zondiconsNetworkIcon} width="16px" marginRight="12px" />
          <StyledTitle mode={mode}>Choose Network</StyledTitle>
        </StyledTitleWrapper>
      )}
      <StyledList>
        {!hideNetwork &&
          switchNetworks.map((chain) => {
            const isActiveChain = chain.id === chainId;
            return (
              <StyledNetworkHover key={chain.id} onClick={() => handleSwitchNetwork(chain)}>
                <StyledNetwork>
                  {isActiveChain && <StyledCheckMark src={checkMarkIcon} width="16px" />}
                  <StyledIconImg src={chain.icon} width="18px" marginRight="8px" />
                  <StyledNetworkName isActive={isActiveChain} mode={mode}>
                    {chain.chainName}
                  </StyledNetworkName>
                </StyledNetwork>
              </StyledNetworkHover>
            );
          })}
      </StyledList>
      <StyledMobileRender>
        <StyledTitleWrapper>
          <UnbindAddressButton />
        </StyledTitleWrapper>
      </StyledMobileRender>
    </StyledWrapper>
  );
};

export default NetworkSwitchList;
