import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router';
import { Loading } from '@components/Loading';
import Page from '@pages/Page';
import useApplicationContext from '@providers/applicationContext';
import { utilityCollectionDefaultImg } from '@styles/imgMapping';
import { useGetUtilityCollections } from '@/api/getUtilityCollections';
import { IUtilityCollectionById } from '@/api/getUtilityCollections/useGetUtilityCollections';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import loadingEffect from '@/loading.json';
import { usePageContext } from '@/providers/pageProvider';
import { StyledContainer, StyledTitle, StyledWarn } from './Styles';
import { UtilityCollectionCard } from './UtilityCollectionCard';

const UtilityCollectionPage = () => {
  const { account, chainId: metamaskChainId } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();
  const [utilityCollections, setUtilityCollections] = useState<IUtilityCollectionById[]>([]);
  const { currentChainType } = useApplicationContext();
  const { updateError } = usePageContext();

  const { loadingGetUtilityCollections, getUtilityCollections } = useGetUtilityCollections();
  const history = useHistory();

  const loading = loadingGetUtilityCollections;
  const address = account || joyIdAddress || okxAddress;
  const chainId = metamaskChainId || okxChainId || joyIdchainId;
  const handleClick = (uuid: string) => () => {
    if (loading) return;
    history.push(`/campaigns/${uuid}`);
  };

  useEffect(() => {
    if (chainId) {
      getUtilityCollections({ type: currentChainType, chainId: Number(chainId) })
        .then((data) => {
          updateError(undefined);
          setUtilityCollections(data as IUtilityCollectionById[]);
        })
        .catch((err) => {
          updateError(err);
          setUtilityCollections([]);
        });
    }
  }, [currentChainType, address, chainId]);

  return (
    <>
      <Loading effect={loadingEffect} isLoading={loading} />
      <StyledTitle>Current Events</StyledTitle>
      {!loading && utilityCollections.length === 0 && <StyledWarn>No events.</StyledWarn>}
      <StyledContainer>
        {utilityCollections
          .sort((lhs, rhs) => (lhs.startTime > rhs.startTime ? -1 : 1))
          .map(({ id, name, mediaUrls, startTime, endTime, redeemedCount, maximumRedemptions }) => (
            <UtilityCollectionCard
              key={id}
              name={name}
              imageUrl={mediaUrls.image || mediaUrls.video}
              startTime={startTime}
              endTime={endTime}
              loading={loading}
              defaultImg={utilityCollectionDefaultImg}
              onClick={handleClick(id)}
              redeemedCount={redeemedCount}
              maximumRedemptions={maximumRedemptions}
            />
          ))}
      </StyledContainer>
    </>
  );
};

const UtilityCollectionWithContext = () => (
  <Page>
    <UtilityCollectionPage />
  </Page>
);

export default UtilityCollectionWithContext;
