import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { ethers } from 'ethers';
import { Loading } from '@components/Loading';
import { ghostFrightenedIcon } from '@styles/imgMapping';
import { safeMintABI } from '@/abi/safeMintABI';
import { Logger } from '@/api/logger';
import { ErrorModal } from '@/components/ErrorModal';
import { SuccessModal } from '@/components/SuccessModal';
import { getJoyIDProvider } from '@/configs/joyidConfigs';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import loadingEffect from '@/loading.json';
import { AppError } from '@/providers/pageProvider';
import { StyledButton, StyledImage, StyledText, StyledWrapper } from './Style';
import { networkContract } from './config';

type Props = {
  utilityCollectionId: string;
  onRefresh: () => void;
};

const EventIsEmpty = ({ utilityCollectionId, onRefresh }: Props) => {
  const { account, chainId: injectionChainId, provider } = useWeb3React();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();
  const { okxAddress, okxChainId, okxProvider } = useOKXWallet();
  const joyIdProvider = getJoyIDProvider(joyIdchainId);

  const [notice, setNotice] = useState<{ title: string; option: AppError } | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const address = account || joyIdAddress || okxAddress;
  const chainId = injectionChainId || joyIdchainId || okxChainId;

  const getProvider = () => {
    if (account) {
      const sig = provider?.getSigner();
      return sig;
    }

    if (okxAddress) {
      const sig = okxProvider?.getSigner();
      return sig;
    }

    if (joyIdAddress) {
      const sig = joyIdProvider.getSigner();
      return sig;
    }

    return undefined;
  };

  const getFreeMint = async () => {
    try {
      setLoading(true);
      const { nftAddress, mintAddress } = networkContract[chainId!];
      const signer = getProvider();
      const c = new ethers.Contract(mintAddress, safeMintABI, signer);
      await c.safeMint(nftAddress, address, 1);
      setNotice({
        title: 'Success',
        option: { message: 'Please refresh to check the NFT.' } as AppError,
      });
    } catch (error) {
      setNotice({
        title: 'Error',
        option: { message: 'NFT can only be claimed once.' } as AppError,
      });
    } finally {
      setLoading(false);
    }
  };

  const blackList: string[] = ['flipster'];
  if (blackList.includes(utilityCollectionId)) {
    return (
      <StyledWrapper>
        <StyledImage src={ghostFrightenedIcon} />
        <StyledText>Don’t see your NFT, get your NFT?</StyledText>
        <StyledText>Import NFTs</StyledText>
        <StyledButton>Get NFT</StyledButton>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <Loading effect={loadingEffect} isLoading={loading} />
      <StyledImage src={ghostFrightenedIcon} />
      <StyledText>Don’t see your NFT, get your NFT?</StyledText>
      <StyledText>Import NFTs</StyledText>
      <StyledButton onClick={getFreeMint}>Request an NFT</StyledButton>
      <ErrorModal
        title={notice?.title || ''}
        opened={notice?.title === 'Error'}
        onClose={() => setNotice(undefined)}
        error={notice?.option || ({ message: '' } as AppError)}
      />
      <SuccessModal
        title={notice?.title || ''}
        opened={notice?.title === 'Success'}
        onClose={() => setNotice(undefined)}
        message={notice?.option.message || ''}
      />
    </StyledWrapper>
  );
};

export default EventIsEmpty;
