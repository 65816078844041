import styled, { css } from 'styled-components';
import { SM_AND_UP_QUERY } from '../breakpoints';

export const Row = styled.div<{
  justify?: string;
  align?: string;
  wrap?: string;
  useCursor?: boolean;
  direction?: string;
}>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify};
  flex-wrap: ${({ wrap }) => wrap};
  cursor: ${({ useCursor }) => (useCursor ? 'pointer' : '')};
`;

export const StyledIconImg = styled.img<{
  width?: string;
  marginRight?: string;
  marginLeft?: string;
  color?: string;
  mobileWidth?: string;
  mobileMarginRight?: string;
  mobileMarginLeft?: string;
}>`
  width: ${({ width, mobileWidth }) => mobileWidth || width};
  margin-right: ${({ marginRight, mobileMarginRight }) => mobileMarginRight || marginRight};
  margin-left: ${({ marginLeft, mobileMarginLeft }) => mobileMarginLeft || marginLeft};
  color: ${({ color }) => color};

  @media ${SM_AND_UP_QUERY} {
    width: ${({ width }) => width};
    margin-right: ${({ marginRight }) => marginRight};
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`;

export const StyledDesktopRender = styled.div`
  display: none;

  @media ${SM_AND_UP_QUERY} {
    display: block;
  }
`;

export const StyledMobileRender = styled.div`
  display: block;
  width: 100%;

  @media ${SM_AND_UP_QUERY} {
    display: none;
  }
`;

export const fontStyle = (size: string, weight?: string) => css`
  font-size: ${size};
  font-weight: ${weight || 'normal'};
`;

export const fontEllipsis = (endLine: number) => css`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${endLine};
  -webkit-box-orient: vertical;
`;

export const size = (width: string, height: string) => css`
  width: ${width};
  height: ${height};
`;

export const StyledMaskIcon = styled.div<{
  src: string;
  width: string;
  mr?: string;
  pointer?: boolean;
  notChangeColor?: boolean;
}>`
  mask-repeat: no-repeat;
  mask-position: center;
  ${({ width }) => size(width, width)};
  margin-right: ${({ mr }) => mr};
  mask-image: ${({ src }) => `url(${src})`};
  background: ${({ theme, notChangeColor }) => {
    if (notChangeColor) return '';
    return theme.colors.brightDream;
  }};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : '')};
`;
