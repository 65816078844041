/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { getConfig } from '@joyid/evm';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router';
import { ConnectButton } from '@components/ConnectButton';
import { Footer, Mode } from '@components/Footer';
import { SelectWallet } from '@configs/evmConnecters';
import useApplicationContext from '@providers/applicationContext';
import { useAuthContext } from '@providers/authProvider';
import customConfig from '@styles/customConfig';
import { walletConnectImg, walletJoyIdImg, walletMetamaskImg, walletOKXImg } from '@styles/imgMapping';
import { ErrorModal } from '@/components/ErrorModal';
import { chains } from '@/configs/evmWalletConfigs';
import { useJoyIdWallet } from '@/hooks/useJoyIdWallet';
import { useOKXWallet } from '@/hooks/useOKXWallet';
import { AppError } from '@/providers/pageProvider';
import { BindingModal } from './BindingModal';
import { Logo } from './Logo';
import {
  StyledAutoSpace,
  StyledConnectDescription,
  StyledConnectTitle,
  StyledContainer,
  StyledDisconnectButton,
  StyledImg,
  StyledSignContainer,
  StyledWrapper,
} from './Styles';

const getSwitchNetwork = (chainId?: number) => {
  if (!chainId) return [];

  const availableChains = chains.filter((chain) => chain.id === chainId);

  return availableChains.map((chain) => {
    const { display, id, rpc, metamaskDefaultRPC, nativeCurrency } = chain;
    return {
      id,
      url: metamaskDefaultRPC || rpc,
      chainName: display,
      nativeCurrency,
      icon: chain.icon,
    };
  });
};

const Home = () => {
  const history = useHistory();

  const { account, chainId } = useWeb3React();
  const { okxAddress, okxChainId } = useOKXWallet();
  const { joyIdAddress, joyIdchainId } = useJoyIdWallet();

  const {
    selectWallet,
    setTriggerSignWallet,
    appError,
    setAppError,
    deactivateWallet,
    isJoyIdConnected,
    handleMetamaskSwitchNetwork,
    handleOKXSwitchNetwork,
    handleJoyIdSwitchNetwork,
    defaultChainId,
  } = useApplicationContext();
  const { accessToken, resetAuth } = useAuthContext();

  const handleSignButton = () => {
    setTriggerSignWallet(true);
  };

  const handleUnbind = async () => {
    await deactivateWallet();
    resetAuth();
    setTriggerSignWallet(false);
  };

  const handleGoUtilityCollection = () => {
    history.push('/campaigns');
  };

  const getAddress = () => account;

  const address = getAddress();

  const getWalletImage = () => {
    switch (selectWallet) {
      case SelectWallet.META_MASK:
        return walletMetamaskImg;
      case SelectWallet.WALLET_CONNECT:
        return walletConnectImg;
      case SelectWallet.JOY_ID:
        return walletJoyIdImg;
      case SelectWallet.OKX:
        return walletOKXImg;
      default:
        return '';
    }
  };

  const convertAddressToSimple = () => {
    let addr = address;
    if (joyIdAddress) {
      addr = joyIdAddress;
    }
    if (okxAddress) {
      addr = okxAddress;
    }

    const prefixStr = addr?.substring(0, 6) || '';
    const suffixStr = addr?.substring(addr.length - 4) || '';
    return `${prefixStr}...${suffixStr}`;
  };

  const showWallets = () => {
    const isEvm = !accessToken && !address;
    const joyID = !accessToken && !isJoyIdConnected;
    const okx = !accessToken && !okxAddress;
    return isEvm && joyID && okx;
  };

  const showSignModal = () => {
    const isEvmSign = !accessToken && address && !checkSwitchNetwork();
    const joyIDSign = !accessToken && isJoyIdConnected && !checkSwitchNetwork();
    const okxSign = !accessToken && okxAddress && !checkSwitchNetwork();
    return isEvmSign || joyIDSign || okxSign;
  };

  const checkSwitchNetwork = () => {
    if (account && chainId !== defaultChainId) return true;
    if (okxAddress && okxChainId !== defaultChainId) return true;
    if (isJoyIdConnected && joyIdchainId !== defaultChainId) return true;
    return false;
  };

  const showSwitchNetwork = () => !accessToken && checkSwitchNetwork();

  const showInformation = () => !!accessToken;

  return (
    <StyledContainer>
      <StyledAutoSpace>
        <Logo />
        {showWallets() && <BindingModal />}
        {showSignModal() && (
          <StyledWrapper>
            <StyledImg src={getWalletImage()} selectWallet={selectWallet} />
            <StyledConnectDescription>{convertAddressToSimple()}</StyledConnectDescription>
            <StyledConnectTitle>Wallet Connected</StyledConnectTitle>
            <StyledConnectDescription>Sign the message with your wallet in order to continue.</StyledConnectDescription>
            <ConnectButton name="Sign" onClick={handleSignButton} />
            <StyledDisconnectButton onClick={handleUnbind}>Disconnect</StyledDisconnectButton>
          </StyledWrapper>
        )}
        {showSwitchNetwork() && (
          <StyledWrapper>
            <StyledImg src={getWalletImage()} />
            <StyledConnectDescription>{convertAddressToSimple()}</StyledConnectDescription>
            <StyledConnectTitle>Switch Network</StyledConnectTitle>
            <StyledConnectDescription>Sign the message with your wallet in order to continue.</StyledConnectDescription>
            {getSwitchNetwork(defaultChainId).map((network) => (
              <ConnectButton
                name={network.chainName.toUpperCase()}
                onClick={() => {
                  if (account) {
                    handleMetamaskSwitchNetwork(network);
                  }
                  if (okxAddress) {
                    handleOKXSwitchNetwork(network);
                  }
                  if (joyIdAddress) {
                    handleJoyIdSwitchNetwork(network);
                  }
                }}
              />
            ))}
            <StyledDisconnectButton onClick={handleUnbind}>Disconnect</StyledDisconnectButton>
          </StyledWrapper>
        )}
        {showInformation() && (
          <StyledSignContainer>
            <ConnectButton name="Information" onClick={handleGoUtilityCollection} />
          </StyledSignContainer>
        )}
      </StyledAutoSpace>
      <Footer forSales mode={Mode.DARK} customConfig={customConfig} />
      <ErrorModal
        title="Error"
        opened={!!appError}
        onClose={() => setAppError(undefined)}
        error={appError as AppError}
      />
    </StyledContainer>
  );
};

export default Home;
