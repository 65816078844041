import { useCallback, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebaseConfig';

export interface IGetUtilityCollectionsPayload {
  type: string;
  chainId: number;
  utilityCollectionId: string;
}

export interface IUtilityCollectionById {
  id: string;
  name: string;
  description: string;
  mediaUrls: {
    image: string;
    video: string;
  };
  startTime: string;
  endTime: string;
  contractAddresses: string[];
  redeemedDescription: string;
  maximumRedemptions: number;
  redeemedCount: number;
}

function useGetUtilityCollection() {
  const [loading, setLoading] = useState(false);
  const getUtilityCollectionCallable = useCallback(
    (params: IGetUtilityCollectionsPayload) => httpsCallable(functions, 'getUtilityCollection')(params),
    [],
  );
  const getUtilityCollection = useCallback(
    async (payload: IGetUtilityCollectionsPayload): Promise<IUtilityCollectionById> => {
      setLoading(true);
      try {
        const response: any = (await getUtilityCollectionCallable(payload)).data;
        setLoading(false);
        const data = response.data as IUtilityCollectionById;
        return Promise.resolve(data);
      } catch (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    },
    [getUtilityCollectionCallable],
  );

  return {
    getUtilityCollection,
    loadingGetUtilityCollection: loading,
  };
}

export default useGetUtilityCollection;
