import { DiscordIcon, FacebookIcon, LinkedinIcon, LogoImage, MediumIcon, TwitterIcon } from '@styles/imgMapping';

interface ICustomConfig {
  [componentKey: string]: {
    [fieldKey: string]: {
      isShow?: boolean;
      link?: string;
      imgSrc?: string;
      imgHoverSrc?: string;
    };
  };
}

export default {
  footer: {
    logo: {
      link: 'https://www.domin.foundation/',
      imgSrc: LogoImage,
      width: '317',
      mobileWidth: '200',
    },
    linkedin: {
      isShow: true,
      link: 'https://www.linkedin.com/showcase/domin-network/',
      imgSrc: LinkedinIcon,
    },
    twitter: {
      isShow: true,
      link: 'https://twitter.com/domin_network',
      imgSrc: TwitterIcon,
    },
    discord: {
      isShow: true,
      link: 'https://discord.com/invite/JHWFFQKCYX',
      imgSrc: DiscordIcon,
    },
    // facebook: {
    //   isShow: true,
    //   link: 'https://www.facebook.com/redreamer.io',
    //   imgSrc: FacebookIcon,
    // },
    // medium: {
    //   isShow: true,
    //   link: 'https://medium.com/@redreamer.io',
    //   imgSrc: MediumIcon,
    // },
  },
} as ICustomConfig;
