import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { BinanceIcon, EthereumIcon, PolygonIcon, ThunderCoreIcon } from '../styles/imgMapping';

export enum AvailableChainId {
  BSC = 56,
  POLYGON = 137,
  CONFLUX = 1030,
  MANTA = 169,
  MINT = 185,
  IOTEX = 4689,
  JFIN = 3501,
  OKX = 66,
  BITLAYER = 200901,
  MORPH = 2810,
  // GM = 202402181627,
  OPBNB = 204,
  // ARBITRUM = 42161,
  BASE = 8453,
  LINEA = 59144,
  VICTION = 88,
  BERA = 80084,
  ZETA = 7000,
}

// mapping mainnet and testnet to chain alias
export const chains = [
  {
    id: 1,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://mainnet.infura.io/v3/895203abafe34efb9af3597f0c105c00',
    metamaskDefaultRPC: 'https://mainnet.infura.io/v3/',
    enabled: false,
    network: 'mainnet',
    display: 'ETH',
    icon: EthereumIcon,
  },
  {
    id: 11155111,
    name: 'eth',
    shortName: 'ETH',
    rpc: 'https://sepolia.infura.io/v3/ebec24a431234429a6f464da2e4f98c5',
    metamaskDefaultRPC: 'https://sepolia.infura.io/v3/',
    enabled: false,
    network: 'testnet',
    display: 'Ethereum Testnet - Sepolia',
    nativeCurrency: {
      name: 'Sepolia ETH',
      symbol: 'SepoliaETH',
      decimals: 18,
    },
    icon: EthereumIcon,
  },
  {
    id: 56,
    name: 'BNB Smart Chain Mainnet',
    shortName: 'BSC',
    rpc: 'https://bsc.blockpi.network/v1/rpc/public',
    enabled: true,
    network: 'mainnet',
    display: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    icon: BinanceIcon,
  },
  {
    id: 97,
    name: 'bnb',
    shortName: 'BNB',
    rpc: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    enabled: false,
    network: 'testnet',
    display: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Testnet BNB',
      symbol: 'tBNB',
      decimals: 18,
    },
    icon: BinanceIcon,
  },
  {
    id: 137,
    name: 'polygon',
    shortName: 'Polygon',
    rpc: 'https://polygon-rpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    icon: PolygonIcon,
  },
  {
    id: 80001,
    name: 'polygon',
    shortName: 'Polygon',
    rpc: 'https://polygon-mumbai-bor-rpc.publicnode.com',
    enabled: false,
    network: 'testnet',
    display: 'Polygon Testnet - Mumbai',
    nativeCurrency: {
      name: 'Testnet MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    icon: PolygonIcon,
  },
  {
    id: 1030,
    name: 'Conflux eSpace',
    shortName: 'Conflux',
    rpc: 'https://evm.confluxrpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Conflux eSpace',
    nativeCurrency: {
      name: 'CFX',
      symbol: 'CFX',
      decimals: 18,
    },
    icon: 'https://confluxnetwork.org/favicon.ico',
  },
  {
    id: 71,
    name: 'Conflux eSpace (Testnet)',
    shortName: 'Conflux',
    rpc: 'https://evmtestnet.confluxrpc.com',
    enabled: false,
    network: 'testnet',
    display: 'Conflux eSpace (Testnet)',
    nativeCurrency: {
      name: 'Testnet CFX',
      symbol: 'CFX',
      decimals: 18,
    },
    icon: 'https://confluxnetwork.org/favicon.ico',
  },
  {
    id: 169,
    name: 'Manta Pacific Mainnet',
    shortName: 'Manta',
    rpc: 'https://manta-pacific.drpc.org',
    enabled: true,
    network: 'mainnet',
    display: 'Manta Pacific Mainnet',
    nativeCurrency: {
      name: 'Manta',
      symbol: 'ETH',
      decimals: 18,
    },
    icon: 'https://manta.network/favicon.ico',
  },
  {
    id: 185,
    name: 'Mint Mainnet',
    shortName: 'Mint',
    rpc: 'https://rpc.mintchain.io',
    enabled: true,
    network: 'mainnet',
    display: 'Mint Mainnet',
    nativeCurrency: {
      name: 'Mint',
      symbol: 'ETH',
      decimals: 18,
    },
    icon: 'https://www.mintchain.io/favicon.ico',
  },
  {
    id: 2810,
    name: 'Morph Holesky Testnet',
    shortName: 'Morph',
    rpc: 'https://rpc-quicknode-holesky.morphl2.io',
    enabled: true,
    network: 'testnet',
    display: 'Morph Holesky Testnet',
    nativeCurrency: {
      name: 'Morph',
      symbol: 'ETH',
      decimals: 18,
    },
    icon: 'https://www.morphl2.io/favicon.ico',
  },
  {
    id: 4689,
    name: 'IoTeX Network Mainnet',
    shortName: 'IoTeX',
    rpc: 'https://babel-api.mainnet.iotex.io',
    enabled: true,
    network: 'mainnet',
    display: 'IoTeX Network Mainnet',
    nativeCurrency: {
      name: 'IoTeX',
      symbol: 'IOTX',
      decimals: 18,
    },
    icon: 'https://iotex.io/favicon.ico',
  },
  {
    id: 3501,
    name: 'JFIN Chain',
    shortName: 'JFIN',
    rpc: 'https://rpc.jfinchain.com',
    enabled: true,
    network: 'mainnet',
    display: 'JFIN Chain',
    nativeCurrency: {
      name: 'JFIN',
      symbol: 'JFIN',
      decimals: 18,
    },
    icon: 'https://www.jfincoin.io/favicon.ico',
  },
  {
    id: 66,
    name: 'OKXChain Mainnet',
    shortName: 'OKX',
    rpc: 'https://1rpc.io/oktc',
    enabled: true,
    network: 'mainnet',
    display: 'OKXChain Mainnet',
    nativeCurrency: {
      name: 'OKX',
      symbol: 'OKT',
      decimals: 18,
    },
    icon: 'https://www.okx.com/cdn/assets/imgs/226/EB771F0EE8994DD5.png',
  },
  // {
  //   id: 202402181627,
  //   name: 'GM Network Testnet',
  //   shortName: 'GM Network',
  //   rpc: 'https://gmnetwork-testnet.alt.technology',
  //   enabled: true,
  //   network: 'testnet',
  //   display: 'GM Network Testnet',
  //   nativeCurrency: {
  //     name: 'GM Network',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   icon: 'https://gmnetwork.ai/favicon.ico',
  // },
  {
    id: 200901,
    name: 'Bitlayer Mainnet',
    shortName: 'Bitlayer',
    rpc: 'https://rpc.bitlayer.org',
    enabled: true,
    network: 'mainnet',
    display: 'Bitlayer Mainnet',
    nativeCurrency: {
      name: 'Bitlayer',
      symbol: 'BTC',
      decimals: 18,
    },
    icon: 'https://www.bitlayer.org/favicon.ico',
  },
  {
    id: 204,
    name: 'opBNB Mainnet',
    shortName: 'opBNB',
    rpc: 'https://opbnb-rpc.publicnode.com',
    enabled: true,
    network: 'mainnet',
    display: 'opBNB Mainnet',
    nativeCurrency: {
      name: 'opBNB',
      symbol: 'BNB',
      decimals: 18,
    },
    icon: 'https://static.bnbchain.org/opbnb-ui/static/images/favicon.ico',
  },
  // {
  //   id: 42161,
  //   name: 'Arbitrum One',
  //   shortName: 'Arbitrum',
  //   rpc: 'https://arbitrum.llamarpc.com',
  //   enabled: true,
  //   network: 'mainnet',
  //   display: 'Arbitrum One',
  //   nativeCurrency: {
  //     name: 'Arbitrum',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   icon: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg',
  // },
  {
    id: 8453,
    name: 'Base',
    shortName: 'Base',
    rpc: 'https://base.llamarpc.com',
    enabled: true,
    network: 'mainnet',
    display: 'Base',
    nativeCurrency: {
      name: 'Base',
      symbol: 'ETH',
      decimals: 18,
    },
    icon: 'https://icons.llamao.fi/icons/chains/rsz_base.jpg',
  },
  {
    id: 88,
    name: 'Viction',
    shortName: 'Viction',
    rpc: 'https://rpc.viction.xyz',
    enabled: true,
    network: 'mainnet',
    display: 'Viction',
    nativeCurrency: {
      name: 'Viction',
      symbol: 'VIC',
      decimals: 18,
    },
    icon: 'https://s3.coinmarketcap.com/static-gravity/image/27a84e20e6274f779fc947f526476253.jpg',
  },
  {
    id: 59144,
    name: 'Linea',
    shortName: 'Linea',
    rpc: 'https://linea.decubate.com',
    enabled: true,
    network: 'mainnet',
    display: 'Linea',
    nativeCurrency: {
      name: 'Linea',
      symbol: 'ETH',
      decimals: 18,
    },
    icon: 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg',
  },
  {
    id: 80084,
    name: 'Berachain bArtio',
    shortName: 'Berachain',
    rpc: 'https://bartio.rpc.berachain.com',
    enabled: true,
    network: 'mainnet',
    display: 'Berachain bArtio',
    nativeCurrency: {
      name: 'Berachain',
      symbol: 'BERA',
      decimals: 18,
    },
    icon: 'https://www.berachain.com/icon.svg?6da89393a3c6f696',
  },
  {
    id: 7000,
    name: 'ZetaChain Mainnet',
    shortName: 'ZetaChain',
    rpc: 'https://zetachain-evm.blockpi.network/v1/rpc/public',
    enabled: true,
    network: 'mainnet',
    display: 'ZetaChain Mainnet',
    nativeCurrency: {
      name: 'ZetaChain',
      symbol: 'ZETA',
      decimals: 18,
    },
    icon: 'https://www.zetachain.com/zh-TW/favicon/apple-icon.png?v=3',
  },
].filter((c) => c.enabled);

export const chainMapping = keyBy(chains, 'id');

export const walletRpcConnectConfig = mapValues(chainMapping, (c) => c.rpc);

export const getSwitchNetworks = () =>
  chains.map((chain) => {
    const { display, id, rpc, metamaskDefaultRPC, nativeCurrency } = chain;
    return {
      id,
      url: metamaskDefaultRPC || rpc,
      chainName: display,
      nativeCurrency,
      icon: chain.icon,
    };
  });
