import styled from 'styled-components';
import { Row, fontStyle } from '@styles/common';
import { SM_AND_UP_QUERY } from '@/breakpoints';

export const StyledWalletAddressWrapper = styled(Row)`
  @media ${SM_AND_UP_QUERY} {
    width: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const StyledWalletAddress = styled.div`
  ${fontStyle('16px', '500')};
  color: ${({ theme }) => theme.colors.brightDream};

  @media ${SM_AND_UP_QUERY} {
    ${fontStyle('14px', '500')};
  }
`;
